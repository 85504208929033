import React, { useState, useEffect } from 'react';
import { withStyles, useTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { compose } from 'recompose';
import ReplayIcon from '@material-ui/icons/Replay';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
	root: {
		width: '100%',
		backgroundColor: 'black',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row',
		},
	},
	button: {
		fontFamily: `${theme.manakin.defaultTitleFont}!important`,
		fontSize: '2rem',
		height: '10rem',
		display: 'none',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		textDecoration: 'none',
		transition: 'background-color .3s',
		cursor: 'pointer',
		backgroundColor: theme.manakin.LessonButtonColor[500],
		color: theme.palette.getContrastText(theme.manakin.LessonButtonColor[500]),
		padding: theme.spacing(2),
		letterSpacing: '4px',
		position: 'relative',
		[theme.breakpoints.up('md')]: {
			fontSize: '4rem',
			height: '40rem',
			fontWeight: '600',
		},
		'&:hover': {
			backgroundColor: theme.manakin.LessonButtonColor[700],
		},
	},
	backToBoxButton: {
		display: 'flex',
	},
	redoQuestionButton: {
		width: '100%',
		margin: 0,
	},
	nextQuestionButton: {
		display: 'none',
		width: '100%',
		textDecoration: 'none',
		margin: 0,
		color: theme.palette.getContrastText(theme.manakin.LessonButtonColor[50]),
		backgroundColor: theme.manakin.LessonButtonColor[50],
		'&:hover': {
			backgroundColor: theme.manakin.LessonButtonColor[100],
		},
	},
	question: {
		position: 'relative',
		zIndex: '2',
	},
	iconContainer: {
		position: 'absolute',
		left: '50%',
		top: '50%',
		transform: 'translate(-50%, -50%)',
		zIndex: '1',
		display: 'none',
	},
	icon: {
		color: 'black',
		width: '7rem',
		height: '7rem',
		[theme.breakpoints.up('md')]: {
			width: '25rem',
			height: '25rem',
		},
	},
	secondaryIcon: {
		color: theme.manakin.indicationColor[500],
	},
	closeIcon: {
		width: '4.2rem',
		height: '4.2rem',
		[theme.breakpoints.up('md')]: {
			width: '18rem',
			height: '18rem',
		},
	},
});

const Bottom = (props) => {
	const { classes, box, lessonData } = props;
	const theme = useTheme();
	const { t } = useTranslation();

	const [next, setNext] = useState(null);

	const handleClick = (id) => {
		if (props.onClick) props.onClick(id);
	};

	useEffect(() => {
		box &&
			box.elements &&
			lessonData.id &&
			box.elements.forEach((item, idx) => {
				if (item.id == lessonData.id) {
					if (idx + 1 < box.elements.length) {
						if (
							box.elements[idx + 1].type !== 'Bridge' &&
							box.elements[idx + 1].type !== 'SlideshowCreator' &&
							box.elements[idx + 1].type !== 'FactCardCollection' &&
							box.elements[idx + 1].type !== 'ExternalLinkCollection'
						) {
							setNext(`/${box.elements[idx + 1].type}/${box.elements[idx + 1].id}`);
						}
					}
				}
			});
	}, [box]);

	return (
		<div className={classes.root}>
			<Link className={classNames(classes.button, classes.backToBoxButton)} to={`/box/${box.id}`}>
				<div className={classes.iconContainer}>
					<CloseIcon className={classes.icon} />
				</div>
				<div className={classes.question}>{t('common.back').toLocaleUpperCase()}</div>
			</Link>
			<div className={classNames(classes.button, classes.redoQuestionButton)} onClick={() => handleClick(lessonData.id)}>
				<div className={classes.iconContainer}>
					{theme.manakin.refreshIcon ? (
						<img
							alt={t('common.close')}
							src={theme.manakin.refreshIcon}
							className={classNames(classes.icon, classes.closeIcon)}
						/>
					) : (
						<ReplayIcon className={classes.icon} />
					)}
				</div>
				<div className={classes.question}>{t('app.elements.lesson.redo')}</div>
			</div>
			{next && (
				<Link to={next} className={classes.nextQuestionButton}>
					<div variant="contained" className={classNames(classes.button, classes.nextQuestionButton, next)}>
						<div className={classes.iconContainer}>
							{theme.manakin.nextIcon ? (
								<img
									alt={t('app.elements.lesson.next-question')}
									src={theme.manakin.nextIcon}
									className={classNames(classes.icon, classes.closeIcon)}
								/>
							) : (
								<ArrowForwardIosIcon className={classes.icon} />
							)}
						</div>
						<div className={classes.question}>{t('app.elements.lesson.next-question')}</div>
					</div>
				</Link>
			)}
		</div>
	);
};

export default compose(withStyles(styles, { name: 'AppLessonBottom' }))(Bottom);
