import React, { useEffect, useRef } from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import classNames from 'classnames';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import FileIcon from '@manakin/core/icons/File';
import { ApiKeys, Loader } from '@manakin/core';
import { useAssetUpload } from '@manakin/hooks';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		display: 'inline-block',
		width: '100%',
	},
	input: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		opacity: 0,
	},
	uploadBox: {
		width: '100%',
		height: 100,
		padding: theme.spacing(1),
		marginTop: theme.spacing(4),
		border: '2px dashed #ccc',
		marginBottom: 10,
		borderRadius: 10,
		cursor: 'pointer',
		boxShadow: 'none',
		display: 'flex',
		justifyContent: 'center',
	},
	noFileText: {
		color: theme.manakin.defaultTextColor['500'],
		fontSize: '18px',
		alignSelf: 'center',
		overflow: 'hidden',
		textAlign: 'center',
	},
	icon: {
		width: '80px',
		height: '50px',
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		color: theme.manakin.defaultTextColor['500'],
		marginRight: '1rem',
	},
	content: {
		padding: '1rem',
		display: 'flex',
		alignItems: 'center',
		width: '100%',
	},
	text: {
		width: '100%',
		'& p': {
			margin: 0,
			maxWidth: '43rem',
		},
	},
	title: {
		fontSize: '1rem',
		lineHeight: '1.2rem',
		marginBottom: '.8rem',
	},
	disabledLabel: {
		color: theme.manakin.disabledFieldLabelColor,
	},
	disabledInput: {
		color: theme.manakin.disabledFieldTextColor,
	},
}));

const FileUpload = (props) => {
	const { form, name, idOnly, initialValue, enabled, label = '', fullWidth = true, readOnly, loading, onError, onChange, accept } = props;
	const classes = useStyles();
	const { t } = useTranslation();
	const { errors, schema } = form;
	const { fields } = form;
	const isRequired = (schema[name] || {}).required;
	const fileUpload = useRef(null);
	const mounted = useRef(false);
	const [fetch, { loading: fetchLoading, data, error, input }] = useAssetUpload(ApiKeys.fileUpload);

	let inputLabelProps = {
		required: (schema[name] || {}).required,
		className: classes.label,
		shrink: true,
	};

	const handleChange = (file) => {
		if (onChange) onChange();
		if (idOnly) {
			fetch(file);
		} else {
			form.onFieldChange({ key: name, value: file });
		}
	};

	useEffect(() => {
		form.onFieldChange({ key: name, value: initialValue });
	}, []);

	useEffect(() => {
		if (!loading) {
			form.onFieldChange({ key: name, value: initialValue });
		}
	}, [loading]);

	useEffect(() => {
		if (!fetchLoading && error && onError) onError(error);
		else if (!fetchLoading && data) {
			form.onFieldChange({ key: name, value: { id: data.id || null, name: input.name || '' } });
		}
	}, [fetchLoading, data, error]);

	useEffect(() => {
		if (mounted.current) {
			fileUpload.current.click();
			mounted.current = true;
		}
		return () => (mounted.current = false);
	}, [enabled]);

	return (
		<div className={classes.root}>
			<FormControl fullWidth={fullWidth} error={errors[name] && errors[name].length > 0}>
				<InputLabel
					{...inputLabelProps}
					classes={{
						root: classNames(classes.label, {
							[classes.disabledLabel]: readOnly,
						}),
					}}
				>
					{label}
					{isRequired && ' *'}
				</InputLabel>
				<div className={classes.uploadBox}>
					{fields[name] ? (
						<div className={classes.content}>
							<FileIcon className={classes.icon} />

							<div className={classes.text}>
								<Typography component="p" variant="h6" classes={{ h6: classes.title }}>
									{fields[name] && fields[name].name}
								</Typography>
							</div>
							<Typography component="p" variant="body1">
								{t('common.edit')}
							</Typography>
						</div>
					) : (
						<div className={classes.noFileText}>{t('common.fields.upload-file.label')}</div>
					)}
					<input
						type="file"
						className={classes.input}
						ref={fileUpload}
						required
						onChange={({
							target: {
								validity,
								files: [file],
							},
						}) => validity.valid && handleChange(file)}
					/>
					{fetchLoading && <Loader />}
				</div>
				{errors[name] && errors[name].length > 0 && errors[name].map((e, i) => <FormHelperText key={i}>{e}</FormHelperText>)}
			</FormControl>
		</div>
	);
};

export default FileUpload;
