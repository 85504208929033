import React, { useState, useEffect } from 'react';
import PageLayout from '../PageLayout';
import { SelectField } from '@manakin/core';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Product } from '../elements';
import { GQL_FETCH_SERVER_INFO } from '../graphql';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';

const styles = (theme) => ({
    wrapper: {
        ...theme.manakin.smallWrapper,
    },
    topContent: {
        display: 'flex',
        '& >*': {
            width: '50%',
        },
    },
    body: {
        marginLeft: '6rem',
        color: theme.manakin.primaryColor[500],
    },
    products: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '4rem',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },
    },
    largeWrapper: {
        ...theme.manakin.largeWrapper,
    },
    footNode: {
        padding: '2rem',
        border: `1px solid ${theme.manakin.defaultBorderColor[500]}`,
    },
    smallFont: {
        fontSize: '1.6rem',
        margin: 0,
        color: theme.manakin.primaryColor[500],
    },
});

const TrainingMandatory = (props) => {
    const { classes, form, isEtos } = props;

    //query
    const { loading, error, data = {} } = useQuery(GQL_FETCH_SERVER_INFO);
    const { serverInfo = {} } = data;

    //state hooks
    const [userFunction, setUserFunction] = useState(
        form.fields.function || 'Assistent drogist'
    );
    const [amount, setAmount] = useState(0);
    const [currentYears, setCurrentYears] = useState([]);
    const [price, setPrice] = useState('41,36');

    useEffect(() => {
        props.onReady(amount > 0);
    }, [amount]);

    useEffect(() => {
        if (!loading) {
            if (serverInfo.currentYear) {
                const currentYear = serverInfo.currentYear;
                setPrice(currentYear >= 2023 ? '41,36' : '37,60');
                setCurrentYears([
                    currentYear - 2,
                    currentYear - 1,
                    currentYear,
                ]);
            }
        }
    }, [loading]);

    //fiunctions
    const handleClick = (data) => {
        if (data.value) setAmount(amount + 1);
        else if (amount > 0) setAmount(amount - 1);
    };

    const handleChange = (data) => {
        setUserFunction(data.value);
    };

    return (
        <PageLayout title="Jouw nascholing">
            <div className={classes.content}>
                <div className={classes.wrapper}>
                    <div className={classes.topContent}>
                        <SelectField
                            noLoading={true}
                            options={[
                                { id: 'Drogist', label: 'Drogist' },
                                {
                                    id: 'Assistent drogist',
                                    label: 'Assistent drogist',
                                },
                            ]}
                            name="function"
                            label="Ik ben:"
                            form={form}
                            className={classes.dropdown}
                            onChange={handleChange}
                            initialValue={{
                                id:
                                    (form.fields && form.fields.function) ||
                                    'Assistent drogist',
                            }}
                        />
                        <Typography variant="body1" className={classes.body}>
                            U koopt automatisch bij het klikken op Naar betalen
                            een licentie voor dit jaar. Selecteer eventueel ook
                            de andere jaren waarvoor je een
                            nascholingsverplichting hebt.
                        </Typography>
                    </div>
                </div>
                <div className={classes.content}>
                    <div className={classes.largeWrapper}>
                        <div className={classes.products}>
                            {currentYears &&
                                currentYears.map((year) => (
                                    <Product
                                        key={year}
                                        name={`trainingMandatory${year}`}
                                        onClick={handleClick}
                                        year={year}
                                        price={
                                            isEtos ? '' : `${price} (excl. BTW)`
                                        }
                                        userFunction={userFunction}
                                        form={form}
                                        initialValue={
                                            form.fields &&
                                            form.fields[
                                                `trainingMandatory${year}`
                                            ]
                                        }
                                    />
                                ))}
                        </div>
                    </div>
                    <div className={classes.wrapper}>
                        <div className={classes.footNode}>
                            <Typography
                                variant="body1"
                                className={classes.smallFont}
                            >
                                Let op: de licentie eindigt op 31 december,
                                ongeacht of alle magazines zijn afgerond.
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        </PageLayout>
    );
};

export default withStyles(styles)(TrainingMandatory);
