'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styles = require('../styles');

var _styles2 = require('@material-ui/core/styles');

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _reactHooks = require('@apollo/react-hooks');

var _graphql = require('../graphql');

var _appCore = require('@manakin/app-core');

var _reactI18next = require('react-i18next');

var _lib = require('@manakin/app-core/lib');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Manager = function Manager(props) {
	var classes = props.classes,
	    appUser = props.appUser;

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	//reducers


	var standardReducer = function standardReducer(state, data) {
		return state + (data.length || 0);
	};

	var _useReducer = (0, _react.useReducer)(standardReducer, 0),
	    _useReducer2 = _slicedToArray(_useReducer, 2),
	    noClassStudents = _useReducer2[0],
	    setNoClassStudent = _useReducer2[1];

	var _useReducer3 = (0, _react.useReducer)(standardReducer, 0),
	    _useReducer4 = _slicedToArray(_useReducer3, 2),
	    studentsInClass = _useReducer4[0],
	    setStudentsInClass = _useReducer4[1];

	var _useReducer5 = (0, _react.useReducer)(standardReducer, 0),
	    _useReducer6 = _slicedToArray(_useReducer5, 2),
	    teachers = _useReducer6[0],
	    setTeachers = _useReducer6[1];

	var _useReducer7 = (0, _react.useReducer)(standardReducer, 0),
	    _useReducer8 = _slicedToArray(_useReducer7, 2),
	    schoolClasses = _useReducer8[0],
	    setSchoolClasses = _useReducer8[1];

	//state hooks


	var _useState = (0, _react.useState)([]),
	    _useState2 = _slicedToArray(_useState, 2),
	    schools = _useState2[0],
	    setSchools = _useState2[1];

	var _useState3 = (0, _react.useState)(null),
	    _useState4 = _slicedToArray(_useState3, 2),
	    schoolIdx = _useState4[0],
	    setSchoolIdx = _useState4[1];

	var _useState5 = (0, _react.useState)(true),
	    _useState6 = _slicedToArray(_useState5, 2),
	    allLoading = _useState6[0],
	    setAllLoading = _useState6[1];

	//queries


	var _useQuery = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_GROUPS, {
		variables: {
			filter: {
				manager: appUser.id
			}
		}
	}),
	    data = _useQuery.data,
	    loading = _useQuery.loading;

	var _useLazyQuery = (0, _reactHooks.useLazyQuery)(_graphql.GQL_FETCH_SCHOOL, {
		onCompleted: function onCompleted(data) {
			handleSchoolData(data);
		}
	}),
	    _useLazyQuery2 = _slicedToArray(_useLazyQuery, 1),
	    fetchSchool = _useLazyQuery2[0];

	//effect hooks


	(0, _react.useEffect)(function () {
		if (!loading && data) {
			if (data.groups && data.groups.groups) {
				var _schools = data.groups.groups.filter(function (i) {
					return i.type === 'School';
				});

				setSchools(_schools);
			}
		}
	}, [data, loading]);

	(0, _react.useEffect)(function () {
		if (schools && schools.length) {
			handleSchools();
		}
	}, [schools]);

	(0, _react.useEffect)(function () {
		if (schools[schoolIdx]) {
			fetchSchool({
				variables: {
					id: schools[schoolIdx].id
				}
			});
		}
	}, [schoolIdx]);

	(0, _react.useEffect)(function () {
		if (schools.length > schoolIdx + 1) {
			setSchoolIdx(schoolIdx + 1);
		} else {
			setAllLoading(false);
		}
	}, [noClassStudents]);

	//functions
	var handleSchools = function handleSchools() {
		setSchoolIdx(0);
	};

	var handleSchoolData = function handleSchoolData() {
		var schoolData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
		var _schoolData$school = schoolData.school,
		    school = _schoolData$school === undefined ? {} : _schoolData$school;

		if (school.managers) {
			var _teachers = school.managers.filter(function (i) {
				return i.roles.some(function (item) {
					return _lib.teacherRoles.includes(item.name);
				});
			});
			setTeachers(_teachers);
		}
		if (school.students) {
			var _school$students = school.students,
			    students = _school$students === undefined ? [] : _school$students,
			    _school$classes = school.classes,
			    _schoolClasses = _school$classes === undefined ? [] : _school$classes;

			var arr = [];
			var newStudents = [];

			_schoolClasses.forEach(function (item) {
				setStudentsInClass(item.students);
				item.students && item.students.forEach(function (student) {
					if (students && students.some(function (i) {
						return i.id === student.id;
					})) {
						arr.push({ id: student.id });
					}
				});
			});

			students.forEach(function (s) {
				if (!arr.some(function (i) {
					return i.id == s.id;
				})) {
					newStudents.push(s);
				}
			});
			setSchoolClasses(_schoolClasses);
			setNoClassStudent(newStudents);
		}
	};

	if (allLoading || loading) return _react2.default.createElement(_appCore.Loader, null);
	return _react2.default.createElement(
		'div',
		{ className: classes.row },
		_react2.default.createElement(
			'div',
			{ className: classes.specificStatisticItem },
			_react2.default.createElement(
				_Typography2.default,
				{ classes: { h2: (0, _classnames2.default)(classes.statisticsTitle) }, component: 'p', variant: 'h2' },
				noClassStudents || 0
			),
			_react2.default.createElement(
				_Typography2.default,
				{ classes: { body1: (0, _classnames2.default)(classes.stasticsBody) }, component: 'p', variant: 'body1' },
				t('app.statistics-box.students-without-class')
			)
		),
		_react2.default.createElement(
			'div',
			{ className: classes.specificStatisticItem },
			_react2.default.createElement(
				_Typography2.default,
				{ classes: { h2: (0, _classnames2.default)(classes.statisticsTitle) }, component: 'p', variant: 'h2' },
				studentsInClass || 0
			),
			_react2.default.createElement(
				_Typography2.default,
				{ classes: { body1: (0, _classnames2.default)(classes.stasticsBody) }, component: 'p', variant: 'body1' },
				t('app.statistics-box.students-in-class')
			)
		),
		_react2.default.createElement(
			'div',
			{ className: classes.specificStatisticItem },
			_react2.default.createElement(
				_Typography2.default,
				{ classes: { h2: (0, _classnames2.default)(classes.statisticsTitle) }, component: 'p', variant: 'h2' },
				teachers || 0
			),
			_react2.default.createElement(
				_Typography2.default,
				{ classes: { body1: (0, _classnames2.default)(classes.stasticsBody) }, component: 'p', variant: 'body1' },
				_react2.default.createElement(_reactI18next.Trans, { i18nKey: 'app.statistics-box.amount-of-teachers' })
			)
		),
		_react2.default.createElement(
			'div',
			{ className: classes.specificStatisticItem },
			_react2.default.createElement(
				_Typography2.default,
				{ classes: { h2: (0, _classnames2.default)(classes.statisticsTitle) }, component: 'p', variant: 'h2' },
				schoolClasses || 0
			),
			_react2.default.createElement(
				_Typography2.default,
				{ classes: { body1: (0, _classnames2.default)(classes.stasticsBody) }, component: 'p', variant: 'body1' },
				_react2.default.createElement(_reactI18next.Trans, { i18nKey: 'app.statistics-box.amount-of-classes' })
			)
		)
	);
};

exports.default = (0, _styles2.withStyles)(_styles.styles, { name: 'AppStatisticsBoxManager' })(Manager);