import {useLazyQuery, useQuery} from '@apollo/react-hooks';
import React from 'react';
import {Loader} from '@manakin/app-core';
import {GQL_CURRENT_APP_USER, GQL_FETCH_GROUPS, GQL_VALID_LICENSE_CHECK} from '../graphql';
import CheckBrinCode from './CheckBrinCode';
import 'url-search-params-polyfill';
import {useLocation} from 'react-router-dom';
import {Redirect} from 'react-router';

const GateKeeper = (props) => {
    const {data, loading} = useQuery(GQL_CURRENT_APP_USER);
    const {currentAppUser = {}} = data || {};
    const {data: validLicenseData, loading: validLicenseLoading} = useQuery(GQL_VALID_LICENSE_CHECK);
    const [fetchGroups, {called: groupsFetched, data: groupsData, loading: groupsLoading}] = useLazyQuery(GQL_FETCH_GROUPS, {
        variables: {
            page: 0,
            pagesize: 999,
        },
    });

    if (currentAppUser && currentAppUser.id && !groupsFetched) fetchGroups({
        variables: {
            filter: {
                manager: currentAppUser.id,
                teacher: currentAppUser.id,
                student: currentAppUser.id,
            }
        },
    });

    const {groups = {}} = groupsData || {}
    const location = useLocation()
    const params = new URLSearchParams(location.search);
    let externalIds = params.get('brincodes') || '';
    const noSchoolSelected = !currentAppUser.school && (!groups.groups || groups.groups.length < 1);
    console.log("GateKeeper: noSchoolSelected: " +noSchoolSelected);

    console.log('GateKeeper: ' + loading + ' ' + groupsLoading + ' ' + validLicenseLoading + ' ' + (!groupsFetched && currentAppUser));
    if (loading || groupsLoading || validLicenseLoading || (!groupsFetched && currentAppUser)) return <Loader fullScreen/>;
    if (groupsFetched && validLicenseData.validLicenseCheck && !validLicenseData.validLicenseCheck.hasValidLicense) {
        console.log('GateKeeper: No valid license redirect to /auth/sso/error groupsFetched:' + groupsFetched + ' validLicenseData.validLicenseCheck' + validLicenseData.validLicenseCheck + ' validLicenseData.validLicenseCheck.hasValidLicense:' + validLicenseData.validLicenseCheck.hasValidLicense);
        return <Redirect to="/auth/sso/aswatson-error"/>;
    }
    if (groupsFetched && currentAppUser.id && noSchoolSelected) {
        return <CheckBrinCode id={currentAppUser.id} externalIds={externalIds.split(',')}/>;
    }
    if (currentAppUser && currentAppUser.id && !noSchoolSelected) {
        return <Redirect to={`/auth/sso/${currentAppUser.id}`}/>;
    }
    return <Redirect to="/auth/sso/error"/>;
};

export default GateKeeper;
