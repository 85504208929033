import logo from '../assets/logo.svg';

export default async () =>
	await new Promise(async (resolve) => {
		const config = {
			whiteLabel: 'BeautyLevel',
			files: [
				{
					id: 'testfile',
					file: logo,
				},
			],
			pages: {
				appLogin: {
					showHeaderTitle: false,
					headerTitleIsHtml: false,
					showFootNote: false,
				},
				appRegistration: {
					showBirthDate: true,
					showPhoneNumber: true,
					showRegisterType: false,
					showRegisterCode: false,
					registerCodeRequired: false,
					addMoreLicenses: true,
				},
				appNoLicense: {
					showFootNote: false,
				},
				appAccount: {
					showEducation: true,
					hideOrganisation: true,
					hideFunction: true,
					hideDelete: false,
				},
				box: {
					scrollTo: false,
				},
				boxCard: {
					showDescription: true,
				},
				dashboard: {
					scrollTo: false,
				},
				appElementOverlay: {
					showHeaderTitle: true,
				},
			},
			elementCardConfig: {
				noPercentage: true
			},
			caseConfig: {
				redoButtons: true,
			},
			assignmentConfig: {
				redoButtons: true,
			},
			general: {
				hideTimer: false,
				downloadRapportage: true,
				showTags: true,
				secondStatisticsBox: true,
				licenseUrl: 'https://www.visavi.nl/Leermiddelen/',
				showNoSchoolDialog: true
			},
			elements: {
				exerciseCard: true,
			},
			getFile: (id) => config.files.find((file) => file.id === id),
			getPage: (key) => config[key],
		};

		resolve(config);
	});
