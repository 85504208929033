import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultWrapper from './parts/DefaultWrapper';
import Introduction from './parts/Introduction';
import queryString from 'query-string';
import { GQL_SIMPLE_ORDER } from '../graphql';
import { useQuery } from 'react-apollo';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import useStyles from './styles';
import Button from '@material-ui/core/Button';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';

const PaymentStatus = {
	OPEN: 'Open',
	PENDING: 'Pending',
	CANCELLED: 'Cancelled',
	SUCCESS: 'Success',
};

const PaymentStatusComponent = (props) => {
	const { t } = useTranslation();
	const params = useParams();
	const [status, setStatus] = useState(null);
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();
	const parsed = queryString.parse(location.search);
	const config = useSelector((state) => state.config);
	const { general = {} } = config || {};
	const returnedFromMollie = parsed.returned === 'true';

	const { data, startPolling, stopPolling } = useQuery(GQL_SIMPLE_ORDER, {
		variables: {
			id: params.id,
		},
		onCompleted: (result) => {
			if (result && result.simpleOrder && result.simpleOrder.status) {
				setStatus(result.simpleOrder.status);
			}
		},
	});
	const { simpleOrder = {} } = data || {};

	useEffect(() => {
		startPolling(1000);
		return () => {
			stopPolling();
		};
	}, []);

	useEffect(() => {
		if (status) {
			if (status === PaymentStatus.OPEN && simpleOrder.paymentUrl && !returnedFromMollie) {
				console.log('status', status);
				console.log('redirecting to payment url: ' + simpleOrder.paymentUrl);
				window.location.href = simpleOrder.paymentUrl;
			}
			if ((status !== PaymentStatus.OPEN && status !== PaymentStatus.PENDING) && simpleOrder.transactionId) {
				console.log('status', status);
				Cookies.remove('shop');
				stopPolling();

				if (general.cbd) {
					history.push(`/auth/registration/finished${'?trxid=' + simpleOrder.transactionId}`);
				} else {
					setTimeout(() => {
						history.push('/');
					}, 5000);
				}
			}
		}
	}, [status, simpleOrder]);

	const getStatusText = () => {
		switch (status) {
			case PaymentStatus.OPEN:
				return t(returnedFromMollie ? 'authentication.webshop.payment-status.pending' : 'authentication.webshop.payment-status.open');
			case PaymentStatus.CANCELLED:
				return t('authentication.webshop.payment-status.cancelled');
			case PaymentStatus.SUCCESS:
				return t('authentication.webshop.payment-status.success');
			default:
				return t('common.loading');
		}
	};

	const handleClick = () => {
		history.push('/');
	};

	return (
		<DefaultWrapper>
			<div className={classes.defaultWrapper}>
				<Introduction title={t('authentication.webshop.payment-status.title')} />
				<br />
				<Typography className={classes.centered}>{getStatusText()}</Typography>
				{status === PaymentStatus.SUCCESS && (
					<div className={classes.buttonGroup}>
						<Button onClick={handleClick} color="primary" variant="contained"
								className={classes.mainButtonStyle}>
							{t('authentication.webshop.payment-status.to-platform')}
						</Button>
					</div>
				)}
			</div>
		</DefaultWrapper>
	);
};

export default PaymentStatusComponent;
