'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = exports.styles = function styles(theme) {
    var _content, _closeButton;

    return _defineProperty({
        root: {},
        top: _defineProperty({
            width: '100%',
            height: theme.manakin.defaultMobileMenuHeight,
            backgroundColor: 'white',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 99,
            display: 'flex',
            alignItems: 'center',
            paddingLeft: theme.manakin.defaultPadding,
            pointerEvents: 'none'
        }, theme.breakpoints.up('md'), {
            position: 'fixed',
            height: theme.manakin.defaultDesktopMenuHeight,
            paddingLeft: '50px'
        }),
        timer: {
            fontSize: '3rem',
            fontFamily: theme.manakin.defaultContentFont,
            lineHeight: '5.5rem',
            fontWeight: '800',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'black',
            display: 'flex',
            alignItems: 'center'
        },
        title: _defineProperty({
            fontFamily: theme.manakin.defaultTitleFont,
            fontSize: '1.4rem',
            lineHeight: '2rem',
            letterSpacing: '.4rem',
            paddingRight: '6rem'
        }, theme.breakpoints.up('xl'), {
            fontSize: '1.4rem',
            display: 'block'
        }),
        moment: {
            marginLeft: '1.4rem'
        },
        left: _defineProperty({
            backgroundColor: 'white',
            width: '50px',
            height: '100%',
            position: 'fixed',
            top: 0,
            left: 0,
            display: 'none'
        }, theme.breakpoints.up('md'), {
            display: 'block'
        }),
        right: _defineProperty({
            backgroundColor: 'white',
            width: '100px',
            height: '100%',
            position: 'fixed',
            top: 0,
            right: 0,
            display: 'none'
        }, theme.breakpoints.up('md'), {
            display: 'block'
        }),
        bottom: _defineProperty({
            backgroundColor: 'white',
            height: '50px',
            width: '100%',
            bottom: 0,
            left: 0,
            position: 'fixed',
            display: 'none',
            zIndex: '99',
            pointerEvents: 'none'
        }, theme.breakpoints.up('md'), {
            height: '100px',
            display: 'none'
        }),
        trainer: {
            '& $bottom': {
                display: 'block',
                borderTop: '1px solid ' + theme.manakin.defaultBorderColor[500],
                paddingLeft: theme.manakin.defaultPadding,
                whiteSpace: 'nowrap',
                overflow: 'scroll'
            },
            '& $content': {
                padding: '64px 0 50px'
            }
        },
        content: (_content = {
            backgroundColor: 'white',
            width: '100%',
            minHeight: '100vh',
            padding: '64px 0 0',
            display: 'flex'
        }, _defineProperty(_content, theme.breakpoints.up('md'), {
            padding: '100px 100px 0 50px'
        }), _defineProperty(_content, '&>*', {
            marginInline: 'auto'
        }), _content),
        checkBox: _defineProperty({
            width: '30px',
            height: '30px',
            border: '1px solid ' + theme.manakin.defaultBorderColor[500],
            display: 'inline-block',
            color: 'black',
            marginRight: '10px',
            position: 'relative',
            top: '50%',
            transform: 'translateY(-50%)',
            '&>*': {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '1.5rem',
                height: '1.5rem'
            }
        }, theme.breakpoints.up('md'), {
            height: '4rem',
            width: '4rem',
            marginRight: '16px'
        }),
        primary: {
            backgroundColor: theme.manakin.primaryWorkformColor,
            '& $left': { backgroundColor: theme.manakin.primaryWorkformColor[500] },
            '& $right': {
                backgroundColor: theme.manakin.primaryWorkformColor[500]
            },
            '& $top': { backgroundColor: theme.manakin.primaryWorkformColor[500] },
            '& $title': {
                color: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500])
            }
        },
        secondary: {
            backgroundColor: theme.manakin.secondaryWorkformColor[200],
            '& $left': {
                backgroundColor: theme.manakin.secondaryWorkformColor[200]
            },
            '& $right': {
                backgroundColor: theme.manakin.secondaryWorkformColor[200]
            },
            '& $top': {
                backgroundColor: theme.manakin.secondaryWorkformColor[200]
            },
            '& $title': {
                color: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[200])
            }
        },
        white: {
            backgroundColor: theme.manakin.secondaryColor[200],
            '& $left': {
                borderBottom: '1px solid ' + theme.manakin.defaultBorderColor[500]
            },
            '& $right': {
                borderBottom: '1px solid ' + theme.manakin.defaultBorderColor[500]
            },
            '& $top': {
                borderBottom: '1px solid ' + theme.manakin.defaultBorderColor[500]
            }
        },
        transparent: {
            backgroundColor: 'transparent',
            '& $left': {
                borderBottom: '1px solid transparent',
                backgroundColor: 'transparent'
            },
            '& $right': {
                borderBottom: '1px solid transparent',
                backgroundColor: 'transparent'
            },
            '& $top': {
                borderBottom: '1px solid transparent',
                backgroundColor: 'transparent'
            },
            '& $content': {
                backgroundColor: 'transparent'
            }
        },
        lightColor: {
            color: 'white'
        },
        fullWidth: {
            backgroundColor: 'transparent',
            '& $left': { backgroundColor: 'transparent' },
            '& $right': { backgroundColor: 'transparent' },
            '& $top': { backgroundColor: 'transparent' },
            '& $bottom': { backgroundColor: 'transparent' },
            '& $content': {
                padding: 0
            }
        },
        closeButton: (_closeButton = {
            borderRadius: 0,
            boxShadow: 'none',
            height: theme.manakin.defaultMobileMenuHeight,
            width: theme.manakin.defaultMobileMenuHeight,
            position: 'absolute',
            right: 0,
            padding: 0,
            top: 0
        }, _defineProperty(_closeButton, theme.breakpoints.up('md'), {
            width: theme.manakin.defaultDesktopMenuHeight,
            height: theme.manakin.defaultDesktopMenuHeight
        }), _defineProperty(_closeButton, '& svg', {
            transition: 'transform .3s',
            transform: 'rotate(0deg)'
        }), _defineProperty(_closeButton, '&:hover', {
            '& svg': _defineProperty({}, theme.breakpoints.up('md'), {
                transform: 'rotate(90deg)'
            })
        }), _closeButton),
        customHeight: {
            '& $content': {
                minHeight: '0'
            }
        },
        link: {
            pointerEvents: 'visible'
        }
    }, '@media print', {
        top: {
            display: "none"
        }
    });
};