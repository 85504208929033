'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.MESSAGES_ROOT_PATH = exports.ROUTER_ROOT_PATH = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRouterDom = require('react-router-dom');

var _Account = require('../Account');

var _Account2 = _interopRequireDefault(_Account);

var _Licenses = require('../Licenses');

var _Licenses2 = _interopRequireDefault(_Licenses);

var _ModuleRouter = require('@manakin/core/ModuleRouter');

var _ModuleRouter2 = _interopRequireDefault(_ModuleRouter);

var _ChangePasswordWrapper = require('../ChangePassword/ChangePasswordWrapper');

var _ChangePasswordWrapper2 = _interopRequireDefault(_ChangePasswordWrapper);

var _Messages = require('../Messages');

var _Messages2 = _interopRequireDefault(_Messages);

var _News = require('../News');

var _News2 = _interopRequireDefault(_News);

var _Results = require('../Results');

var _Results2 = _interopRequireDefault(_Results);

var _Invoices = require('../Invoices');

var _Invoices2 = _interopRequireDefault(_Invoices);

var _Rapportage = require('../Rapportage');

var _Rapportage2 = _interopRequireDefault(_Rapportage);

var _Invite = require('../Invite');

var _Invite2 = _interopRequireDefault(_Invite);

var _Notifications = require('../Notifications');

var _Notifications2 = _interopRequireDefault(_Notifications);

var _BPVRapport = require('../BPVRapport');

var _BPVRapport2 = _interopRequireDefault(_BPVRapport);

var _Books = require('../Books');

var _Books2 = _interopRequireDefault(_Books);

var _Book = require('../Books/Book');

var _Book2 = _interopRequireDefault(_Book);

var _reactRedux = require('react-redux');

var _SsoPage = require('../SsoPage');

var _SsoPage2 = _interopRequireDefault(_SsoPage);

var _recompose = require('recompose');

var _selectors = require('@manakin/authentication/selectors');

var _constants = require('../../app-core/constants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ROUTER_ROOT_PATH = exports.ROUTER_ROOT_PATH = 'account';
var MESSAGES_ROOT_PATH = exports.MESSAGES_ROOT_PATH = 'account/messages';

var Router = function Router(props) {
	var appUser = props.appUser;

	var isSSO = appUser.loginType === _constants.LoginTypes.ThirdParty;

	return _react2.default.createElement(
		_ModuleRouter2.default,
		null,
		_react2.default.createElement(_reactRouterDom.Route, {
			exact: true,
			path: '/' + ROUTER_ROOT_PATH,
			component: isSSO ? _SsoPage2.default : _Account2.default
		}),
		_react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/' + ROUTER_ROOT_PATH + '/student-rapports', component: _Rapportage2.default }),
		_react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/' + ROUTER_ROOT_PATH + '/licenses', component: isSSO ? _SsoPage2.default : _Licenses2.default }),
		_react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/' + ROUTER_ROOT_PATH + '/notifications', component: _Notifications2.default }),
		_react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/' + ROUTER_ROOT_PATH + '/change-password', component: isSSO ? _SsoPage2.default : _ChangePasswordWrapper2.default }),
		_react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/' + ROUTER_ROOT_PATH + '/messages', component: _Messages2.default }),
		_react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/' + ROUTER_ROOT_PATH + '/news', component: _News2.default }),
		_react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/' + ROUTER_ROOT_PATH + '/results', component: _Results2.default }),
		_react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/' + ROUTER_ROOT_PATH + '/bpvb', component: _BPVRapport2.default }),
		_react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/' + ROUTER_ROOT_PATH + '/books/:bookId', component: _Book2.default }),
		_react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/' + ROUTER_ROOT_PATH + '/books', component: _Books2.default }),
		_react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/' + ROUTER_ROOT_PATH + '/invoices', component: _Invoices2.default }),
		_react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/' + ROUTER_ROOT_PATH + '/invite', component: _Invite2.default })
	);
};

exports.default = (0, _recompose.compose)((0, _reactRedux.connect)(function (state) {
	return {
		appUser: (0, _selectors.getAppUser)(state),
		config: state.config
	};
}), _reactRouterDom.withRouter)(Router);