'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.calculateBoxElementsProgressAndResult = exports.fetchBoxesResults = exports.fetchBoxElementsResults = exports.fetchBoxResults = exports.fetchProgramsResults = exports.fetchProgram = undefined;

var _graphql = require('./graphql');

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _lib = require('@manakin/app-core/lib');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var fetchProgram = exports.fetchProgram = function fetchProgram(client, userData, selectedBoxes, availablePrograms, programId) {
	return new Promise(function (resolve, reject) {
		var program = availablePrograms.find(function (program) {
			return program.id == programId;
		});

		Promise.all(boxes(selectedBoxes, program).map(function (box) {
			return fetchBoxElementsResults(client, userData, box, program);
		})).then(function (boxElementResults) {
			resolve({
				program: program,
				boxElementResults: boxElementResults
			});
		}).catch(function () {
			reject();
		});
	});
};

var fetchProgramsResults = exports.fetchProgramsResults = function fetchProgramsResults(client, userData, selectedPrograms) {
	return new Promise(function (resolve, reject) {
		client.query({
			query: _graphql.GQL_FETCH_PROGRAM_RESULTS,
			variables: {
				programs: selectedPrograms ? selectedPrograms.map(function (program) {
					return program.value || program.id;
				}) : [],
				users: [userData.id]
			}
		}).then(function (result) {
			if (!result.data || !result.data.programResults) return;
			if (result.data.programResults[0] == false) {
				resolve(0);
				return;
			}

			var totalProgramProgress = 0;
			var programAmount = 0;

			result.data.programResults.forEach(function (result) {
				totalProgramProgress += result.progress;
			});

			selectedPrograms.forEach(function () {
				programAmount++;
			});

			resolve(Math.floor(totalProgramProgress / programAmount) || 0);
		}).catch(function () {
			reject();
		});
	});
};

var fetchProgramBoxResults = function fetchProgramBoxResults(client, userData, selectedBoxes, program) {
	return new Promise(function (resolve, reject) {
		Promise.all(boxes(selectedBoxes, program).map(function (box) {
			return fetchBoxResults(client, userData, box, program);
		})).then(function (boxResults) {
			resolve({
				program: program,
				boxResults: boxResults
			});
		}, function () {
			reject();
		}).catch(function () {
			reject();
		});
	});
};

var fetchBoxResults = exports.fetchBoxResults = function fetchBoxResults(client, userData, box, program) {
	return new Promise(function (resolve, reject) {
		client.query({
			query: _graphql.GQL_FETCH_BOX_RESULTS,
			variables: {
				program: program.id,
				box: box.id,
				user: userData.id
			}
		}).then(function (result) {
			if (result.data && result.data.boxResults) {
				resolve({
					box: box,
					progress: result.data.boxResults[0] ? result.data.boxResults[0].progress || 0 : 0
				});
			} else {
				reject();
			}
		}).catch(function () {
			reject();
		});
	});
};

var fetchBoxElementsResults = exports.fetchBoxElementsResults = function fetchBoxElementsResults(client, userData, box, program) {
	return new Promise(function (resolve, reject) {
		client.query({
			query: _graphql.GQL_FETCH_ELEMENT_RESULTS,
			variables: {
				box: box.id,
				program: program.id,
				user: userData.id
			}
		}).then(function (result) {
			if (result.data && result.data.elementResults) {
				resolve({
					box: box,
					elementResults: [].concat(_toConsumableArray(result.data.elementResults))
				});
			} else {
				reject();
			}
		}).catch(function () {
			reject();
		});
	});
};

var fetchBoxesResults = exports.fetchBoxesResults = function fetchBoxesResults(client, userData, selectedPrograms, selectedBoxes, availablePrograms) {
	return new Promise(function (resolve, reject) {
		var programs = selectedPrograms && availablePrograms.filter(function (program) {
			return selectedPrograms.find(function (selectedProgram) {
				return selectedProgram.value === program.id || selectedProgram.id === program.id;
			});
		}) || [];

		Promise.all(programs.map(function (program) {
			return fetchProgramBoxResults(client, userData, selectedBoxes, program);
		})).then(function (programBoxResults) {
			var totalBoxPercentage = 0;
			var totalBoxes = 0;

			var addResults = function addResults(result) {
				totalBoxPercentage += result.progress || 0;
				totalBoxes++;
			};

			var countBoxes = function countBoxes(program) {
				program.boxResults.forEach(function (result) {
					addResults(result);
				});
			};

			programBoxResults.forEach(function (program) {
				countBoxes(program);
			});

			var averagedResultPercentage = function averagedResultPercentage() {
				return Math.floor(totalBoxPercentage / totalBoxes);
			};

			resolve(averagedResultPercentage);
		}, function () {
			reject('The program does not have selected box(es) to fetch');
		}).catch(function () {
			reject('None of the selected programs are available');
		});
	});
};

var calculateBoxElementsProgressAndResult = exports.calculateBoxElementsProgressAndResult = function calculateBoxElementsProgressAndResult(selectedBoxes, selectedElements, programBoxesResults) {
	var learningResults = [];
	var totalElementPercentage = 0;
	var amountOfElements = selectedElements.length;

	programBoxesResults.forEach(function (programBoxesResult) {
		programBoxesResult.boxElementResults.forEach(function (selectedBox) {
			if (selectedBoxes.find(function (box) {
				return box.value == selectedBox.box.id;
			})) {
				var selectedElementsResults = [];

				selectedBox.elementResults.filter(Boolean).forEach(function (result) {
					if (selectedElements && selectedElements.find(function (element) {
						return element.value == result.element.id;
					})) {
						selectedElementsResults.push(result);
					}
				});

				if (selectedElementsResults.length) {
					selectedElementsResults.forEach(function (element) {
						learningResults.push(getRating(element));
					});
				}

				selectedElementsResults.forEach(function (element) {
					totalElementPercentage += getElementProgress(element);
				});
			}
		});
	});

	return {
		learningResult: (0, _lib.getMostFrequentInArray)(learningResults),
		progress: Math.floor(totalElementPercentage / amountOfElements) || 0
	};
};

var getRating = function getRating(element) {
	switch (element.element.type) {
		case 'Trainer':
			if (element.bestTime && element.bestTime.time) {
				return (0, _moment2.default)().minute(0).second(element.bestTime.time).format('mm:ss');
			}

			return 'nvt';
		case 'ExerciseCard':
			return (0, _lib.getExerciseRating)(element) || 'nvt';
		case 'Lesson':
			return element.finished ? element.rating === 'true' ? 'Voldoende' : 'Onvoldoende' : 'nvt';
		default:
			return 'nvt';
	}
};

var getElementProgress = function getElementProgress(element) {
	if (element && element.element && element.element.type && element.element.type === 'Lesson') {
		return Math.floor(element.progress || 0);
	}

	return element.finished ? 100 : 0;
};

var boxes = function boxes(selectedBoxes, program) {
	return selectedBoxes && program.boxes && program.boxes.map(function (box) {
		return box[0];
	}).filter(function (box) {
		return selectedBoxes.find(function (selectedBox) {
			return selectedBox.value === box.id;
		});
	}) || [];
};