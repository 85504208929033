import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			paddingTop: '6rem',
			minHeight: '100%',
			width: '100vw',
			height: '100vh',
		},
		smallWrapper: {
			maxWidth: theme.manakin.wrapper['small'],
			width: '90%',
			margin: '0 auto',
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
		},
		heading: {
			marginBottom: '20px',
			fontSize: '5rem',
			lineHeight: '5rem'
		},
		paper: {
			padding: theme.spacing(6),
		},
	})
);

const AswatsonError = () => {
	const classes = useStyles();
	const { t } = useTranslation();
    const ssoUrl = process.env.REACT_APP_SSO_URL || '';

	return (
		<div className={classes.root}>
			<div className={classes.smallWrapper}>
				<Paper className={classes.paper}>
					<Typography className={classes.heading} variant="h1">
						{t('authentication.sso.asw-error.title')}
					</Typography>
					<Typography>{t('authentication.sso.asw-error.body')}</Typography>
				</Paper>
			</div>
		</div>
	);
};

export default AswatsonError;
