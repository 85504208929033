import { createMuiTheme } from '@material-ui/core/styles';
import { createTheme, createColor } from '@manakin/theme';
import { green, yellow, beautySecondary, white, grey, black, mountain } from '../colors';
import amber from '@material-ui/core/colors/amber';
import blue from '@material-ui/core/colors/blue';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import loginBackgroundImage from '../../assets/login-background.png';
import accountBackgroundImage from '../../assets/account-background.jpg';
import logo from '../../assets/logo.svg';
import pdfIcon from '../../assets/pdf.svg';
import loginVisual from '../../assets/login-visual.svg';
import footerLogo from '../../assets/logo.svg';
import calendarVisual from '../../assets/calendar-visual.svg';
import reportVisual from '../../assets/report-visual.svg';

const theme = (obj = {}) => {
	const homePageBlockPrimaryBackgroundColor = obj.homePageBlockPrimaryBackgroundColor;
	const homePageBlockSecondaryBackgroundColor = obj.homePageBlockSecondaryBackgroundColor;

	let _obj = {};

	_obj._primary = '#FFECC2';
	_obj._secondary = '#C0E1D7';
	_obj._tertiary = '#F28F7F';
	_obj._palette = obj.themeColor || '#c0e1d7';
	_obj.indication = obj.indicationColor || '#000';
	_obj._primaryWorkform = '#FFECC2';
	_obj._secondaryWorkform = green[500];
	_obj._buttonBorderColor = obj.buttonBorderColor || '#000000';
	_obj.buttonRadius = obj.buttonBorderRadius || '0px';

	//dynamic colors
	_obj.defaultBox = obj.defaultBoxColor || _obj._primary;
	_obj.defaultElement = obj.defaultWorkformColor || obj.defaultElementColor || _obj._primary;
	_obj.primaryElement = obj.primaryWorkformColor || _obj._secondary;
	_obj.secondaryElement = obj.defaultWorkformColor || obj.secondaryElementColor || _obj._secondaryWorkform;
	_obj.defaultWorkform = obj.defaultWorkformColor || _obj._palette;
	_obj.primaryWorkform = obj.primaryWorkformColor || _obj._primaryWorkform;
	_obj.secondaryWorkform = obj.secondaryWorkformColor || _obj._secondaryWorkform;
	_obj.buttonBorderColor = createColor(_obj._buttonBorderColor);

	///////COLORS
	_obj.createdPalette = createColor(_obj._palette);
	_obj.indicationColor = createColor(_obj.indication);
	_obj.defaultBoxColor = createColor(_obj.defaultBox);

	//ELEMENT COLORS
	_obj.defaultElementColor = createColor(_obj.defaultElement);
	_obj.primaryElementColor = createColor(_obj.primaryElement);
	_obj.secondaryElementColor = createColor(_obj.secondaryElement);

	//WORKFORM COLORS
	_obj.defaultWorkformColor = createColor(_obj.defaultWorkform);
	_obj.primaryWorkformColor = createColor(_obj.primaryWorkform);
	_obj.secondaryWorkformColor = createColor(_obj.secondaryWorkform);

	//primary and secondary colors
	_obj.primaryColor = createColor(_obj._primary);
	_obj.secondaryColor = createColor(_obj._secondary);
	_obj.tertiaryColor = createColor(_obj._tertiary);

	//Palette colors (Used for buttons, icons, navigation enz)
	_obj.primaryPaletteColor = black;
	_obj.secondaryPaletteColor = beautySecondary;

	//type colors
	_obj.typeColor = _obj._tertiary;
	_obj.primaryTypeColor = white;
	_obj.secondaryTypeColor = black;

	//box backgroundColors
	_obj.defaultBoxBGColor = _obj.defaultBoxColor;

	//box name colors
	_obj.defaultBoxColor = black;
	_obj.primaryBoxColor = white;
	_obj.secondaryBoxColor = black;

	//element colors
	_obj.defaultElementBGColor = _obj.defaultElementColor;
	_obj.primaryElementBGColor = _obj.primaryElementColor;
	_obj.secondaryElementBGColor = _obj.secondaryElementColor;

	//backgroundColors
	_obj.defaultBackgroundColor = _obj.primaryColor;
	_obj.defaultTitleColor = black;
	_obj.defaultContentColor = black;
	_obj.homeLinkColor = _obj.defaultContentColor;
	_obj.secondaryBackgroundColor = grey;

	//Menu colors
	_obj.mainMenuBackground = _obj.primaryPaletteColor;
	_obj.mainMenuColor = beautySecondary;
	_obj.mainMenuSecondaryColor = white;

	//navigation colors
	_obj.navigationColor = _obj.primaryPaletteColor;
	_obj.secondaryNavigationColor = _obj.primaryPaletteColor;
	_obj.linkColor = _obj.primaryPaletteColor;
	_obj.defaultContentHover = _obj.createdPalette;
	_obj.LessonButtonColor = black;
	_obj.defaultBorderHoverColor = _obj.createdPalette;

	//rest
	_obj.defaultBorderColor = createColor('#E6E6E6');
	_obj.softBorderColor = createColor('#EFEFEF');
	_obj.subHeaderColor = createColor('#8A8A8A');
	_obj.correctAnswer = yellow;
	_obj.loginBackground = createColor('#F5F5F5');
	_obj.homePageBlockPrimaryBackgroundColor = createColor(homePageBlockPrimaryBackgroundColor) || obj.primaryWorkform;
	_obj.homePageBlockSecondaryBackgroundColor = createColor(homePageBlockSecondaryBackgroundColor) || obj.secondaryWorkform;

	_obj.contentWidthXL = '1600px';
	_obj.contentWidthL = '1200px';
	_obj.contentWidthM = '1050px';
	_obj.contentWidthS = '700px';
	_obj.contentWidthXS = '340px';
	//breakpoints
	_obj.smartphoneBreakpoint = '@media only screen and (min-width : 375px)';
	_obj.tabletPortraitBreakpoint = '@media only screen and (min-width : 768px)';
	_obj.tabletLandscapeBreakpoint = '@media only screen and (min-width : 1024px)';
	_obj.laptopBreakpoint = '@media only screen and (min-width : 1280px)';
	_obj.desktopBreakpoint = '@media only screen and (min-width : 1400px)';
	_obj.desktopXLBreakpoint = '@media only screen and (min-width : 2100px)';

	//margins and paddings
	_obj.mainMenuListItemPaddingTop = '25px';
	_obj.mainMenuListItemPaddingBottom = '25px';
	_obj.defaultPadding = '25px';

	//fonts
	_obj.defaultTitleFont = 'Galderglynn';
	_obj.secondaryTitleFont = "'Zilla Slab', serif";
	_obj.defaultAnswerFont = 'Heebo';
	_obj.defaultContentFont = 'Heebo';

	_obj.workformTitleFont = {
		fontFamily: _obj.defaultTitleFont,
		fontSize: '4rem',
		lineHeight: '130%',
		letterSpacing: 0,
		fontWeight: 500,
	};

	//heights
	_obj.defaultMobileMenuHeight = '64px';
	_obj.defaultDesktopMenuHeight = '100px';

	_obj.defaultWrapper = {
		maxWidth: _obj.contentWidthM,
		position: 'relative',
		margin: '0 auto',
		width: '100%',
		paddingLeft: '25px',
		paddingRight: '25px',
	};
	_obj.extraSmallWrapper = {
		..._obj.defaultWrapper,
		maxWidth: _obj.contentWidthXS,
	};
	_obj.smallWrapper = {
		..._obj.defaultWrapper,
		maxWidth: _obj.contentWidthS,
	};
	_obj.largeWrapper = {
		..._obj.defaultWrapper,
		maxWidth: _obj.contentWidthL,
	};
	_obj.extraLargeWrapper = {
		..._obj.defaultWrapper,
		maxWidth: _obj.contentWidthXL,
	};

	_obj.footerLogoStyle = {
		width: '10rem',
		height: '3rem',
		backgroundImage: `url(${footerLogo})`,
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		position: 'absolute',
		right: '10rem',
		bottom: '3rem',
	};

	_obj.registrationHeading = {
		large: {
			textAlign: 'center',
			width: '100%',
			maxWidth: '600px',
			fontSize: '40px',
			lineHeight: '130%',
			margin: '0 auto 1em',
		},
		small: {
			fontSize: '28px',
			letterSpacing: '.2rem',
		},
	};

	_obj.breakpoints = createBreakpoints({});
	const realTheme = _theme(_obj);

	return realTheme;
};

const _theme = (props) =>
	createMuiTheme({
		typography: {
			htmlFontsize: 10,
			fontFamily: [
				props.defaultContentFont,
				'-apple-system',
				'BlinkMacSystemFont',
				'"Segoe UI"',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'sans-serif',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(','),
		},
		palette: {
			primary: props.primaryPaletteColor,
			secondary: props.secondaryPaletteColor,
			success: {
				light: green[200],
				main: green[400],
				dark: green[600],
				contrastText: '#ffffff',
			},
			info: {
				light: blue[300],
				main: blue[400],
				dark: blue[500],
				contrastText: '#ffffff',
			},
			warning: {
				light: amber[400],
				main: amber[600],
				dark: amber[700],
				contrastText: '#ffffff',
			},
		},
		manakin: createTheme({
			accountBackgroundImage: accountBackgroundImage,
			calendarVisual: calendarVisual,
			correctAnswer: props.correctAnswer,
			defaultActiveColor: green,
			defaultAnswerFont: props.defaultAnswerFont,
			defaultBackgroundColor: props.defaultBackgroundColor,
			defaultBorderColor: props.defaultBorderColor,
			defaultBorderHoverColor: props.defaultBorderHoverColor,
			defaultBoxBGColor: props.defaultBoxBGColor,
			defaultBoxColor: props.defaultBoxColor,
			defaultButtonBorderColor: props.buttonBorderColor,
			defaultButtonRadius: props.buttonRadius,
			defaultContentColor: props.defaultContentColor,
			defaultContentFont: props.defaultContentFont,
			defaultContentHover: props.defaultContentHover,
			defaultDesktopMenuHeight: props.defaultDesktopMenuHeight,
			defaultElementBGColor: props.defaultElementBGColor,
			defaultMobileMenuHeight: props.defaultMobileMenuHeight,
			defaultPadding: props.defaultPadding,
			defaultTitleColor: props.defaultTitleColor,
			defaultTitleFont: props.defaultTitleFont,
			defaultWorkformColor: props.defaultWorkformColor,
			defaultWrapper: props.defaultWrapper,
			desktopBreakpoint: props.desktopBreakpoint,
			desktopXLBreakpoint: props.desktopXLBreakpoint,
			extraLargeWrapper: props.extraLargeWrapper,
			extraSmallWrapper: props.extraSmallWrapper,
			homeLinkColor: props.homeLinkColor,
			homePageBlockPrimaryBackgroundColor: props.homePageBlockPrimaryBackgroundColor,
			homePageBlockSecondaryBackgroundColor: props.homePageBlockSecondaryBackgroundColor,
			indicationColor: props.indicationColor,
			laptopBreakpoint: props.laptopBreakpoint,
			largeWrapper: props.largeWrapper,
			layoutButtonStyleSelected: {
				backgroundColor: props.primaryPaletteColor[500],
				border: 0,
				color: white[500],
			},
			LessonButtonColor: props.LessonButtonColor,
			linkColor: props.linkColor,
			loginBackground: props.loginBackground,
			loginBackgroundImage: loginBackgroundImage,
			loginVisual: loginVisual,
			logo: logo,
			mainMenuBackground: props.mainMenuBackground,
			mainMenuColor: props.mainMenuColor,
			mainMenuListItemPaddingBottom: props.mainMenuListItemPaddingBottom,
			mainMenuListItemPaddingTop: props.mainMenuListItemPaddingTop,
			mainMenuSecondaryColor: props.mainMenuSecondaryColor,
			navigationColor: props.navigationColor,
			pdfIcon: pdfIcon,
			primaryBoxColor: props.primaryBoxColor,
			primaryColor: props.primaryColor,
			primaryElementBGColor: props.primaryElementBGColor,
			primaryPaletteColor: props.primaryPaletteColor,
			primaryTypeColor: props.primaryTypeColor,
			primaryWorkformColor: props.primaryWorkformColor,
			reportVisual: reportVisual,
			secondaryBackgroundColor: props.secondaryBackgroundColor,
			secondaryBoxColor: props.secondaryBoxColor,
			secondaryColor: props.secondaryColor,
			secondaryElementBGColor: props.secondaryElementBGColor,
			secondaryNavigationColor: props.secondaryNavigationColor,
			secondaryTitleFont: props.secondaryTitleFont,
			secondaryTypeColor: props.secondaryTypeColor,
			secondaryWorkformColor: props.secondaryWorkformColor,
			smallWrapper: props.smallWrapper,
			smartphoneBreakpoint: props.smartphoneBreakpoint,
			softBorderColor: props.softBorderColor,
			subHeaderColor: props.subHeaderColor,
			tabletLandscapeBreakpoint: props.tabletLandscapeBreakpoint,
			tabletPortraitBreakpoint: props.tabletPortraitBreakpoint,
			tertiaryColor: props.tertiaryColor,
			typeColor: props.typeColor,
			underline: {
				width: '50px',
				height: '2px',
				backgroundColor: props.defaultContentColor[500],
				position: 'absolute',
				bottom: 0,
				left: '50%',
				transform: 'translate(-50%, 2.5rem)',
			},
			visual: {
				backgroundImage: `url(${loginVisual})`,
				width: '100%',
				height: '100%',
				backgroundSize: 'cover',
			},
			workformTitleFont: props.workformTitleFont,
		}),
		overrides: {
			AppAccount: {
				deleteButton: {
					color: '#ec7262',
				},
				confirmDeleteButton: {
					backgroundColor: '#ec7262',
				},
			},
			appBestTimes: {
				user: {
					backgroundColor: props.primaryColor[500],
				},
				currentUser: {
					backgroundColor: black[500],
					'& $body': {
						color: white[500],
					},
					'& $time': {
						color: white[500],
					},
				},
			},
			AppBoxCard: {
				inner: {
					minHeight: '24rem',
					height: 'auto',
					padding: '7rem 4rem',
					[props.breakpoints.up('md')]: {
						height: '50rem',
						minHeight: '50rem',
						padding: '0 15rem',
					},
				},
				innerDone: {
					[props.breakpoints.up('md')]: {
						height: '50rem',
					},
				},
				voltooid: {
					fontSize: '10px',
				},
				listView: {
					textAlign: 'center',
					'& $boxName': {
						textAlign: 'center',
						justifyContent: 'center',
						letterSpacing: 0,
						[props.breakpoints.up('md')]: {
							letterSpacing: 0,
						},
					},
					'&:hover': {
						'& $boxName': {
							opacity: 1,
							transform: 'translateY(0)',
							visibility: 'visible',
						},
					},
					'& img': {
						objectFit: 'cover',
					},
				},
				boxCardLabel: {
					position: 'absolute',
					top: '10px',
					left: 0,
					zIndex: 1,
					padding: '15px 32px',
					borderRadius: '0 32px 32px 0',
					fontWeight: 600,
					fontSize: '13px',
					lineHeight: '1',
					letterSpacing: '2px',
					textTransform: 'uppercase',
					[props.breakpoints.up('md')]: {
						padding: '24px 32px',
						top: '20px',
					},
				},
				tagdone: {
					'&$withLabel': {
						right: '68px',
						[props.breakpoints.up('md')]: {
							right: '84px',
						},
					},
				},
				percentageDone: {
					backgroundColor: green[500],
					width: '4.2rem',
					height: '4.2rem',
					top: '1.3rem',
					borderRadius: '100%',
					[props.breakpoints.up('md')]: {
						width: '6rem',
						height: '6rem',
						top: '2rem',
					},
				},
				done: {
					'& span': {
						display: 'none',
					},
				},
			},
			// AppLesson: {
			//     largeWrapper: {
			//         maxWidth: '1230px',
			//         [props.breakpoints.up('md')]: {
			//             maxWidth: '1230px',
			//         },
			//     },
			//     normalMargin: {
			//         margin: '3rem 0',
			//         [props.breakpoints.up('md')]: {
			//             margin: '8rem 0',
			//         },
			//     },
			// },
			AppBoxes: {
				wrapper: {
					maxWidth: '1250px',
				},
			},
			AppBoxLayout: {
				normalWrapper: {
					[props.breakpoints.up('md')]: {
						marginTop: '-90px',
					},
				},
			},
			AppCheckQuestion: {
				correct: {
					border: '1px solid #e6e6e6',
					borderBottom: '1px solid #e6e6e6',
				},
			},
			AppClassList: {
				licenseName: {
					fontFamily: props.defaultTitleFont,
				},
			},
			AppElementCard: {
				type: {
					color: props.typeColor,
				},
				play: {
					display: 'none',
				},
				done: {
					backgroundColor: green[500],
				},
				inCorrect: {
					backgroundColor: green[500],
				},
				bookCheck: {
					[props.breakpoints.up('md')]: {
						height: '14rem',
						minHeight: '14rem',
					},
				},
				bookCheckContent: {
					[props.breakpoints.up('md')]: {
						marginBottom: '0!important',
					},
				},
				bodytitle: {
					[props.breakpoints.up('md')]: {
						marginBottom: '0!important',
					},
				},
				secondaryBackgroundColor: {
					backgroundColor: yellow[500],
				},
			},
			AppElementOverlay: {
				title: {
					fontSize: '1.1rem',
				},
				secondary: {
					backgroundColor: green[200],
					'& $left': {
						backgroundColor: green[200],
					},
					'& $right': {
						backgroundColor: green[200],
					},
					'& $top': {
						backgroundColor: green[200],
					},
				},
			},
			AppElementsRoot: {
				grid: {
					maxWidth: '1250px',
				},
			},
			AppFactcardReferral: {
				button: {
					color: white[500],
				},
			},
			AppFactcardsCard: {
				bookCheck: {
					backgroundColor: green[500],
				},
				factCardsButton: {
					border: 0,
				},
				bodytitle: {
					color: black[500],
				},
				body: {
					color: black[500],
				},
			},
			AppFirstPage: {
				wrapper: {
					maxWidth: props.contentWidthM,
				},
				titleRoot: {
					[props.breakpoints.up('md')]: {
						fontSize: '6rem',
						lineHeight: '7rem',
						letterSpacing: '.4rem',
					},
					[props.breakpoints.up('lg')]: {
						fontSize: '6rem',
						lineHeight: '7rem',
						letterSpacing: '.4rem',
					},
				},
			},
			AppHotspotMapLandscape: {
				subHeader: {
					'&:after': {
						display: 'none',
					},
				},
			},
			AppImageAndTextCheckQuestion: {
				checked: {
					position: 'absolute',
					width: 'calc(100% + 14px)',
					height: 'calc(100% + 14px)',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%) scale(.85)',
					alignItems: 'center',
					justifyContent: 'center',
					opacity: 0,
					display: 'flex',
					pointerEvents: 'none',
					border: '2px solid black',
					transition: 'transform .2s ease, opacity .2s ease',
					backfaceVisibility: 'hidden',
				},
				notActive: {
					opacity: 1,
				},
				active: {
					border: 0,
				},
			},
			AppImageAndTextMPC: {
				active: {
					backgroundColor: white[500],
					padding: '4px',
				},
				selected: {
					border: '2px solid black',
				},
				correct: {
					border: 0,
					cursor: 'default',
				},
				disabled: {
					opacity: 1,
				},
			},
			AppImageCheckQuestion: {
				selected: {
					position: 'absolute',
					width: 'calc(100% + 14px)',
					height: 'calc(100% + 14px)',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%) scale(.85)',
					alignItems: 'center',
					justifyContent: 'center',
					opacity: 0,
					display: 'flex',
					pointerEvents: 'none',
					border: '2px solid black',
					transition: 'transform .2s ease, opacity .2s ease',
					backfaceVisibility: 'hidden',
				},
				showIcon: {
					opacity: 1,
					transform: 'translate(-50%, -50%) scale(1)',
				},
				notActive: {
					opacity: 1,
				},
				active: {
					border: 0,
				},
			},
			AppImageMPC: {
				checked: {
					position: 'absolute',
					width: 'calc(100% + 14px)',
					height: 'calc(100% + 14px)',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%) scale(.85)',
					alignItems: 'center',
					justifyContent: 'center',
					opacity: 0,
					display: 'flex',
					pointerEvents: 'none',
					border: '2px solid black',
					transition: 'transform .2s ease, opacity .2s ease',
				},
				showIcon: {
					opacity: 1,
					transform: 'translate(-50%, -50%) scale(1)',
				},
				active: {
					opacity: '1',
					border: 0,
					padding: 0,
				},
				disabled: {
					opacity: '1',
				},
				notActive: {
					opacity: 1,
				},
			},
			AppLessonBottom: {
				root: {
					backgroundColor: white[500],
					borderTop: `1px solid ${props.defaultBorderColor[500]}`,
				},
				button: {
					margin: '0 1rem',
					minWidth: '300px',
				},
				backToBoxButton: {
					width: '100%',
				},
				redoQuestionButton: {
					display: 'flex',
					paddingRight: '6rem',
					[props.breakpoints.up('md')]: {
						paddingRight: '16px',
					},
				},
			},
			AppPoll: {
				percentageBarInner: {
					backgroundColor: yellow[900],
					'& p': {
						color: mountain[400],	
					}
				},
				percentageBarOuter: {
					backgroundColor: grey[600],
				},
				formControlRoot: {
					[props.breakpoints.up('md')]: {
						borderTop: `2px solid ${mountain[500]}`,
						borderLeft: `2px solid ${mountain[500]}`,
						borderRight: `2px solid ${mountain[500]}`,
					},'&.Mui-disabled': {
						borderBottom: `2px solid ${mountain[500]}`,
						borderTop: `2px solid ${mountain[500]}`,
					},
					'&:last-child': {
						borderBottom: `2px solid ${mountain[500]}`,
					},
				},
				formControlLabel: {
					color: mountain[500],
					fontSize: '1rem',
					letterSpacing: '.3rem',
					alignItems: 'inherit',
					fontFamily: 'Galderglynn',
					fontWeight: 'bold',
					[props.breakpoints.up('md')]: {
						fontSize: '1.4rem',
						padding: '1rem 0',
						lineHeight: '1.7',
					},
					'&.Mui-disabled': {
						color: mountain[500],
					},
				},
				primary: {
					'& $percentageBarInner': {
						backgroundColor: yellow[900],
					},
					'& $percentageBarOuter': {
						backgroundColor: white[500],
					},
				}
			},
			AppLogin: {
				root: {
					display: 'flex',
					flexDirection: 'column',
					[props.breakpoints.up('lg')]: {
						marginTop: '9rem',
					},
				},
				registrationLink: {
					order: 3,
					marginTop: '5rem',
				},
			},
			appLoginHeader: {
				root: {
					backgroundColor: props.primaryColor[500],
				},
			},
			AppLogout: {
				root: {
					backgroundColor: yellow[500],
				},
			},
			AppOrderQuestion: {
				card: {
					margin: '1rem 0',
					border: 0,
					backgroundColor: yellow[500],
				},
				isCorrect: {
					backgroundColor: green[500],
				},
			},
			AppRadioGroup: {
				correct: {
					border: '1px solid #e6e6e6',
					borderBottom: '1px solid #e6e6e6',
					'& $formControlLabel': {
						color: 'rgba(0, 0, 0, 0.38)',
					},
				},
			},
			AppRegistrationAuthData: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			AppRegistrationPersonalData: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			AppRegistrationSchoolClassData: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			AppRegistrationSchoolData: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			AppRegistrationScreen: {
				root: {
					minHeight: '100vh',
					backgroundColor: yellow[500],
				},
			},
			AppRegistrationSuccess: {
				root: {
					backgroundColor: yellow[500],
				},
				content: {
					margin: '0 0 0px',
				},
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			AppResumeButton: {
				root: {
					backgroundColor: white[500],
					overflow: 'hidden',
					'& $longArrow': {
						'& .arrow': {
							backgroundColor: props.createdPalette[500],
						},
					},
					'&:hover': {
						'& $body1': {
							color: props.createdPalette[500],
						},
						'& $longArrow': {
							'& .arrow': {
								backgroundColor: props.createdPalette[500],
							},
						},
					},
				},
			},
			AppSortSlider: {
				questions: {
					backgroundColor: yellow[500],
				},
				button: {
					borderRadius: 0,
					[props.breakpoints.up('md')]: {
						borderRadius: 0,
					},
				},
			},
			AppStatementQuestion: {
				root: {
					backgroundColor: 'transparent',
				},
				button: {
					borderRadius: 0,
					padding: '36px 20px',
				},
				secondaryColors: {
					backgroundColor: props.secondaryColor[200],
					'& $contentColor': {
						color: black[500],
					},
					'& $button': {
						backgroundColor: black[500],
						color: white[500],
					},
				},
			},
			AppStatisticsBox: {
				statistics: {
					borderColor: 'black',
					[props.breakpoints.up('md')]: {
						borderColor: 'black',
					},
				},
				specificStatisticItem: {
					border: `1px solid ${props.primaryPaletteColor[100]}`,
					[props.breakpoints.up('md')]: {
						border: 0,
					},
				},
				stasticsBody: {
					fontFamily: props.defaultTitleFont,
					letterSpacing: '.4rem',
				},
			},
			AppTitleAndText: {
				header: {
					textTransform: 'none',
					fontFamily: props.defaultContentFont,
					fontWeight: 800,
				},
			},
			AppTitleAndTextImage: {
				root: {
					// maxWidth: '1200px',
					margin: '0 auto',
					backgroundColor: 'transparent',
				},
				header: {
					textTransform: 'none',
					fontFamily: props.defaultContentFont,
					fontWeight: 800,
				},
				secondary: {
					'& $contentColor': {
						color: white[500],
					},
				},
				image: {
					objectFit: 'contain',
				},
			},
			AppVideoPlayer: {
				progressBar: {
					'&> input[type=range]::-webkit-slider-runnable-track': {
						background: green[500],
					},
				},
			},
			AppWhatIsWhatItem: {
				correctIcon: {
					backgroundColor: green[500],
				},
			},
			AuthenticationResetPassword: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			ClaimLicense: {
				root: {
					width: '100%',
					height: '100%',
				},
			},
			ManakinLoginForm: {
				root: {
					backgroundColor: yellow[500],
				},
			},
			MuiBadge: {
				dot: {
					width: '8px',
					height: '8px',
					backgroundColor: 'red',
				},
			},
			MuiButton: {
				root: {
					zIndex: 1,
					color: 'white',
					height: 'auto',
					padding: '20px',
					borderRadius: '0',
				},
				contained: {
					boxShadow: 'none',
				},
				containedPrimary: {
					backgroundColor: 'black',
					color: 'white',
					'&:hover': {
						backgroundColor: 'black',
						color: 'white',
					},
				},
				label: {
					fontFamily: 'Galderglynn',
					fontWeight: 'bold',
					position: 'relative',
					textTransform: 'none',
					zIndex: 1,
					fontSize: '1.4rem',
					letterSpacing: '.4rem',
					textDecoration: 'none',
				},
				outlinedPrimary: {
					border: '2px solid black',
					padding: '18px',
					'&:hover': {
						border: '2px solid black',
					},
				},
			},
			MuiChip: {
				label: {
					fontSize: '14px',
				},
			},
			MuiDialog: {
				paper: {
					margin: '18px',
					[props.breakpoints.up('md')]: {
						padding: '4.4rem 5rem 1rem',
					},
				},
				paperFullWidth: {
					minHeight: '53rem',
					borderRadius: 0,
				},
			},
			MuiDialogActions: {
				root: {
					justifyContent: 'center',
					flexDirection: 'column',
					[props.breakpoints.up('md')]: {
						margin: 0,
					},
				},
				action: {
					width: '100%',
					padding: '2rem',
				},
			},
			MuiDialogContent: {
				root: {
					[props.breakpoints.up('md')]: {
						padding: 0,
					},
				},
			},
			MuiDialogTitle: {
				root: {
					[props.breakpoints.up('md')]: {
						padding: 0,
					},
					'&>h2': {
						marginBottom: '4rem',
					},
				},
			},
			MuiDrawer: {
				paper: {
					padding: '3rem',
					fontFamily: props.defaultContentFont,
					fontSize: '1.8rem',
					color: props.mainMenuColor,
					background: props.mainMenuBackground[500],
					[props.breakpoints.up('md')]: {
						padding: '1.8rem 4rem',
						fontSize: '3rem',
					},
				},
			},
			MuiFab: {
				primary: {
					backgroundColor: black[500],
					color: white[500],
					'&:hover': {
						backgroundColor: black[400],
					},
				},
			},
			MuiFormControl: {
				root: {
					width: '100%',
				},
			},
			MuiFormControlLabel: {
				root: {
					marginRight: '20px',
					width: '40%',
					'@media (min-width: 1400px)': {
						width: 'auto',
						marginRight: '50px',
					},
				},
			},
			MuiFormGroup: {
				root: {
					width: '100%',
					flexDirection: 'row',
				},
			},
			MuiFormHelperText: {
				root: {
					fontSize: '1.25rem',
				},
			},
			MuiFormLabel: {
				root: {
					fontFamily: props.defaultContentFont,
					fontSize: '2rem',
					color: props.defaultContentColor[500],
				},
			},
			MuiInput: {
				input: {
					padding: '14px 0',
					fontSize: '1.8rem',
					lineHeight: '3rem',
				},
			},
			MuiInputBase: {
				inputMultiline: {
					minHeight: '14rem',
				},
			},
			MuiMenuItem: {
				root: {
					fontSize: '1.6rem',
				},
			},
			MuiRadio: {
				colorPrimary: {
					color: 'white',
					'&$checked': {
						color: 'white',
					},
				},
				colorSecondary: {
					color: 'black',
					'&$checked': {
						color: 'black',
					},
				},
			},
			MuiStepConnector: {
				root: {
					display: 'none',
				},
			},
			MuiStepIcon: {
				root: {
					transform: 'scale(1.7)',
					margin: '0 10px',
				},
				text: {
					fontFamily: props.secondaryTitleFont,
					fontSize: '1.2rem',
				},
			},
			MuiStepLabel: {
				iconContainer: {
					padding: '0 20px 0 0',
				},
				labelContainer: {
					display: 'none',
				},
			},
			MuiStepper: {
				root: {
					backgroundColor: 'transparent',
					padding: '0',
					margin: '30px 0',
				},
			},
			MuiSvgIcon: {
				root: {
					width: '2rem',
					height: '2rem',
				},
			},
			MuiTableCell: {
				root: {
					padding: '4px 16px 4px 16px',
				},
				head: {
					border: 0,
					fontSize: '1.2rem',
					color: props.defaultContentColor[500],
					fontFamily: props.defaultTitleFont,
					lineHeight: '2rem',
					letterSpacing: '.4rem',
				},
				body: {
					paddingTop: '1.5rem',
					paddingBottom: '1.5rem',
				},
			},
			MuiTablePagination: {
				caption: {
					fontSize: '1.6rem',
				},
				select: {
					fontSize: '1.6rem',
					lineHeight: '1.6rem',
					paddingRight: 32,
				},
			},
			MuiTypography: {
				root: {
					fontSize: '2rem',
				},
				h1: {
					fontFamily: props.defaultTitleFont,
					fontSize: '3rem',
					fontWeight: '500',
					lineHeight: '4.2rem',
					color: props.defaultTitleColor[500],
					marginBottom: '1.4rem',
					letterSpacing: 0,
					[props.breakpoints.up('md')]: {
						fontSize: '7rem',
						lineHeight: '9rem',
						letterSpacing: '1rem',
					},
				},
				h2: {
					fontFamily: props.defaultTitleFont,
					fontSize: '2.75rem',
					lineHeight: '3.85rem',
					fontWeight: 'bold',
					color: props.defaultContentColor[500],
					letterSpacing: '0.3rem',
					marginBottom: '1.4rem',
					[props.breakpoints.up('md')]: {
						fontSize: '4rem',
						lineHeight: '7rem',
					},
				},
				h3: {
					fontFamily: props.defaultContentFont,
					fontSize: '3rem',
					lineHeight: '150%',
					color: props.defaultContentColor[500],
					fontWeight: 800,
					marginBottom: '1.4rem',
					[props.breakpoints.up('md')]: {
						fontSize: '4rem',
					},
				},
				h4: {
					color: props.defaultContentColor[500],
					fontFamily: props.secondaryTitleFont,
					fontWeight: 500,
					fontSize: '3.6rem',
					lineHeight: '5rem',
					marginBottom: '3.6rem',
					[props.breakpoints.up('md')]: {
						lineHeight: '6rem',
						marginBottom: '4.6rem',
						fontSize: '3.6rem',
					},
				},
				h5: {
					color: props.defaultContentColor[500],
					fontFamily: props.secondaryTitleFont,
					fontSize: '2.6rem',
					fontWeight: 'bold',
					lineHeight: '3.6rem',
					marginBottom: '1.3rem',
					[props.breakpoints.up('md')]: {
						lineHeight: '5.2rem',
						marginBottom: '0rem',
					},
				},
				h6: {
					color: props.defaultContentColor[500],
					fontFamily: props.defaultTitleFont,
					fontSize: '1.8rem',
					fontWeight: 'bold',
					lineHeight: '2rem',
					marginBottom: '1.3rem',
					letterSpacing: '.2rem',
					[props.breakpoints.up('md')]: {
						marginBottom: '1.6rem',
					},
				},
				subtitle1: {
					fontFamily: props.secondaryTitleFont,
					fontSize: '2.2rem',
					lineHeight: '150%',
					color: props.defaultContentColor[500],
					marginBottom: '1.4rem',
					fontWeight: '500',
				},
				subtitle2: {
					fontFamily: props.secondaryTitleFont,
					fontSize: '2.4rem',
					lineHeight: '150%',
					color: props.defaultContentColor[500],
					marginBottom: '1.4rem',
					fontWeight: '500',
					[props.breakpoints.up('md')]: {
						marginBottom: '4rem',
					},
				},
				body1: {
					fontSize: '1.8rem',
					color: props.defaultContentColor[500],
					lineHeight: '3.6rem',
					marginBottom: '2rem',
					[props.breakpoints.up('md')]: {
						marginBottom: '3rem',
					},
				},
				body2: {
					fontSmoothing: 'auto',
					color: props.defaultContentColor[500],
					fontWeight: 600,
					fontSize: '2.2rem',
					lineHeight: '4rem',
					fontFamily: props.secondaryTitleFont,
					marginBottom: '2rem',
					[props.breakpoints.up('md')]: {
						fontWeight: 400,
					},
				},
				gutterBottom: {
					marginBottom: '3em',
				},
			},
		},
	});

export default theme;
