import gql from 'graphql-tag';

export const GQL_FETCH_SERVER_INFO = gql`
    query {
        serverInfo {
            currentYear
        }
    }
`;

export const GQL_FETCH_APP_USER_FOR_STORE = gql`
    query {
        currentAppUser {
            id
            licenses {
                validUntil
                code
                product {
                    id
                    name
                }
                licenseGroup {
                    id
                    name
                }
            }
            schoolClasses {
                id
                name
                schools {
                    id
                    organisations {
                        id
                        mainOrganisation {
                            id
                            name
                        }
                    }
                }
            }
            userDataCbd {
                trainingMandatory2016
                trainingMandatory2017
                trainingMandatory2018
                trainingMandatory2019
                trainingMandatory2020
                trainingMandatory2021
                trainingMandatory2022
                trainingMandatory2023
                trainingMandatory2024
                trainingMandatory2025
                function
            }
        }
    }
`;

export const GQL_UPDATE_APP_USER = gql`
    mutation($id: ID!, $userDataCbdInput: UserDataCbdInput) {
        updateAppUser(input: { id: $id, userDataCbdInput: $userDataCbdInput }) {
            user {
                id
            }
        }
    }
`;

export const GQL_VALID_LICENSE_CHECK = gql`
    query {
        validLicenseCheck {
            hasValidLicense
        }
    }
`;
