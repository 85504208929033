import React, { useEffect, useCallback } from 'react';
import AppViews from '@manakin/app-views/Router';
import { AppBar, SearchBar, IdleTimer, HeartBeat } from '@manakin/app-core';
import { AppAccount } from '@manakin/app-account';
import AppMenu from '../AppMenu';
import { CURRENT_USER } from './graphql';
import { useQuery } from '@apollo/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '@manakin/authentication/actions';
import { getAppUser } from '@manakin/authentication/selectors';
import { useSetupReadSpeaker, useAppSettings, useCopyProtection, useAppPermissions, useSetupScriptTags } from '@manakin/hooks';

const App = () => {
    //queries
    const { data = {}, loading } = useQuery(CURRENT_USER);

    //selectors
    const appUser = useSelector((state) => getAppUser(state));

    //dispatch functions
    const dispatch = useDispatch();
    const onDone = useCallback((user) => dispatch(login('/', user)), [
        dispatch,
    ]);

    //effect hooks
    //Rerender user when new license
    useEffect(() => {
        if (!loading && data.currentAppUser) {
            if (appUser.licenses) {
                if (
                    data.currentAppUser.licenses &&
                    appUser.licenses.length !==
                        data.currentAppUser.licenses.length
                ) {
                    onDone(data.currentAppUser);
                }
            } else {
                onDone(data.currentAppUser);
            }
        }
        //eslint-disable-next-line
    }, [data, loading]);

    useAppSettings();
    useCopyProtection();
    //setup read speaker
    useSetupReadSpeaker();
    useAppPermissions(data && data.currentAppUser);
    useSetupScriptTags();

    return (
        <HeartBeat>
            <div className="App">
                <IdleTimer />
                <AppBar />
                <AppAccount />
                <AppViews />
                <AppMenu />
                <SearchBar />
            </div>
        </HeartBeat>
    );
};

export default App;
