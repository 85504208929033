'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styles = require('@material-ui/core/styles');

var _appCore = require('@manakin/app-core');

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _reactRouterDom = require('react-router-dom');

var _reactRenderHtml = require('react-render-html');

var _reactRenderHtml2 = _interopRequireDefault(_reactRenderHtml);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

require('moment/min/locales');

var _recompose = require('recompose');

var _reactI18next = require('react-i18next');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
    var _root, _end;

    return {
        body1: {},
        title: {},
        longArrow: {
            transform: 'translateX(0)',
            transition: 'transform .2s',
            '& .arrow': {
                background: theme.manakin.defaultContentColor[500]
            }
        },
        root: (_root = {
            cursor: 'pointer',
            backgroundColor: 'white',
            width: '100%',
            minHeight: '7rem',
            display: 'flex',
            alignItems: 'center',
            padding: '1.8rem',
            position: 'relative',
            textDecoration: 'none',
            flexDirection: 'column',
            '& $body1': _defineProperty({
                margin: 0,
                marginRight: '1.4rem',
                textAlign: 'left',
                transition: 'color .2s'
            }, theme.breakpoints.up('md'), {
                marginRight: '3.6rem'
            }),
            '& $title': {
                fontWeight: '900',
                textAlign: 'left'
            },
            '&:hover': {
                '& $longArrow': {
                    transform: 'translateX(5px)',
                    '& .arrow': {
                        backgroundColor: theme.manakin.defaultContentHover[500]
                    }
                },
                '& $body1': {
                    color: theme.manakin.defaultContentHover[500]
                }
            }
        }, _defineProperty(_root, theme.breakpoints.up('md'), {
            padding: '1.8rem 22.8rem 1.8rem 12.8rem',
            minHeight: '10rem',
            flexDirection: 'row'
        }), _defineProperty(_root, theme.breakpoints.down("sm"), {
            padding: "1.8rem 1.8rem 1.8rem 116px",
            alignItems: "start"
        }), _root),
        themeIcon: {
            width: '7.4rem',
            height: '7.4rem',
            marginRight: '3.6rem'
        },
        time: {},
        end: (_end = {
            position: 'absolute',
            right: '4rem',
            top: '50%',
            transform: 'translateY(-50%)',
            alignItems: 'center'
        }, _defineProperty(_end, theme.breakpoints.up('md'), {
            display: 'flex'
        }), _defineProperty(_end, theme.breakpoints.down("xs"), {
            display: "none"
        }), _end),
        buttonText: {},
        imageContainer: _defineProperty({
            width: '100px',
            height: '100px',
            marginRight: '17px'
        }, theme.breakpoints.up('sm'), {
            display: 'block'
        }),
        image: _defineProperty({
            width: '100px',
            maxHeight: '112px',
            alignSelf: "baseline"
        }, theme.breakpoints.up('sm'), {
            display: 'block'
        })
    };
};

var ResumeButton = function ResumeButton(props) {
    var classes = props.classes;

    var _useTranslation = (0, _reactI18next.useTranslation)(),
        t = _useTranslation.t;

    var time = _moment2.default.unix(props.time).locale(t("common.locale-code")).calendar();

    console.log(props.name);

    var buttonText = props.buttonText || props.name;

    return _react2.default.createElement(
        _reactRouterDom.Link,
        { className: classes.root, to: '/box/' + props.id },
        props.theme && _react2.default.createElement(_appCore.ThemeIcon, {
            variant: props.theme,
            className: classes.themeIcon
        }),
        props.image && props.image.url && _react2.default.createElement(_appCore.LoadImage, {
            src: props.image.url || '',
            placeholder: props.image.thumbnailUrl || '',
            className: classes.image,
            align: 'right'
        }),
        _react2.default.createElement(
            _Typography2.default,
            { className: (0, _classnames2.default)(classes.body1, classes.time) },
            time
        ),
        _react2.default.createElement(
            _Typography2.default,
            { className: (0, _classnames2.default)(classes.body1, classes.title) },
            (0, _reactRenderHtml2.default)(buttonText)
        ),
        _react2.default.createElement(
            'div',
            { className: classes.end },
            _react2.default.createElement(
                _Typography2.default,
                {
                    className: (0, _classnames2.default)(classes.body1, classes.buttonText)
                },
                t("common.resume")
            ),
            _react2.default.createElement(_appCore.LongArrow, { className: classes.longArrow })
        )
    );
};

exports.default = (0, _recompose.compose)((0, _styles.withStyles)(styles, { name: 'AppResumeButton' }))(ResumeButton);