import gql from 'graphql-tag';
import { appAssetWithThumbnail } from '@manakin/app-core/graphQlHelpers';

export const GQL_FETCH_NEWS_LIST = gql`
    query($filter: NewsListFilter, $pagesize: Int, $page: Int) {
        newsList(filter: $filter, pagesize: $pagesize, page: $page) {
            news {
                id
                title
                introText
                category
                factCards
                content
                image {
                    ${appAssetWithThumbnail}
                }
                lastModifiedOn
                createdOn
            }
            count
        }
    }
`;

export const GQL_FETCH_BOX_RESULTS = gql`
    query($program: ID!, $user: ID!) {
        boxResults(program: $program, user: $user) {
            user {
                id
            }
            id
            lastModifiedOn
            finishedOn
            finished
            rating
            box {
                id
            }
            text
        }
    }
`;

export const GQL_FETCH_APP_USER = gql`
    query($id: ID!) {
        appUser(id: $id) {
            id
            userDataCbd {
                trainingMandatory2016
                trainingMandatory2017
                trainingMandatory2018
                trainingMandatory2019
                trainingMandatory2020
                trainingMandatory2021
                trainingMandatory2022
                trainingMandatory2023
                trainingMandatory2024
                trainingMandatory2025
                function2016
                function2017
                function2018
                function2019
                function2020
                function2021
                function2022
                function2023
                function2024
                function2025
                function
            }
        }
    }
`;
