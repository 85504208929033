import { makeStyles, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.manakin.primaryPaletteColor[500],
		position: 'relative',
		cursor: 'pointer',
		transform: 'scale(1)',
		transition: 'transform .3s',
		'&:hover': {
			transform: 'scale(1.01)',
			backgroundColor: theme.manakin.primaryPaletteColor[700],
		},
		padding: '5px',
		gap: '5px',
	},
	time: {
		margin: 0,
		fontSize: '11px',
		fontWeight: 'bold',
		lineHeight: '12px',
		zIndex: 2,
		color: theme.palette.getContrastText(theme.manakin.primaryPaletteColor[500]),
	},
	event: {
		margin: 0,
		fontSize: '14px',
		fontWeight: 'bold',
		lineHeight: '14px',
		zIndex: 2,
		marginTop: '5px',
		color: theme.palette.getContrastText(theme.manakin.primaryPaletteColor[500]),
	},
}));

const Event = (props) => {
	const { event = {} } = props;
	const { date, title } = event;
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Typography className={classes.time}>{moment(date).format('HH:mm')}</Typography>
			<Typography className={classes.event}>{title || ''}</Typography>
		</div>
	);
};

export default Event;
