import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Login from '@manakin/app-views/Login';
import Reset2Factor from '@manakin/app-views/Reset2Factor';
import Confirm2FactorReset from '@manakin/app-views/Reset2Factor/Confirm2FactorReset';
import ResetPassword from '@manakin/app-views/ResetPassword';
import ConfirmReset from '@manakin/app-views/ResetPassword/ConfirmReset';
import AppRegistrationView from '../AppRegistrationView';
import AppConfirmRegistrationView from '../AppConfirmRegistrationView';
import AppInvitation from '../AppInvitation';
import {AswatsonError, Error, GateKeeper, Sso} from '../Sso';
import {TransitionRouter} from '@manakin/app-core';
import AppRegistrationSuccess from '../AppRegistrationSuccess';
import InvitationSucces from '../AppInvitation/InvitationSucces';
import Logout from '../Logout';
import {ErrorToken, LoginToken} from '../LoginToken';
import TwoFactor from '../TwoFactor/TwoFactor';
import AppConfirmInvitation from '../ConfirmInvitation/AppConfirmInvitation';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import Homepage from '../Homepage/Homepage';
import SsoLogin from '@manakin/app-views/SsoLogin';
import {useSelector} from 'react-redux';
import PayScreen from '../AppWebshop/PayScreen';
import Webshop from '../AppWebshop/Webshop';
import PaymentStatus from '../AppWebshop/PaymentStatus';
import PaymentFinished from '../PaymentFinished/PaymentFinished';
import AppRegistrationCBD from '../AppRegistrationCBD/AppRegistration';
import {useAppSettings} from '@manakin/hooks';

const useStyles = makeStyles(
    (theme) =>
        createStyles({
            root: {
                minHeight: '100vh',
                backgroundColor: theme.manakin.loginBackground[500],
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                ' &>.fullWidth': {
                    margin: 'auto',
                },
            },
        }),
    {
        name: 'AuthenticationAppAuthenticator',
    }
);

const AppAuthenticator = (props) => {
    const {match} = props;
    const classes = useStyles();
    const config = useSelector((state) => state.config);
    const {general = {}} = config || {};
    const isIosApp = navigator.userAgent.indexOf('wrapper') !== -1;

    useAppSettings();

    if (general.sso && general.disableLoginPage) {
        return (
            <div className={classes.root}>
                <Switch>
                    <Route exact path={`${match.url}/sso/aswatson-error`} component={AswatsonError}/>
                    <Route exact path={`${match.url}/sso/new`} component={GateKeeper}/>
                    <Route exact path={`${match.url}/sso/error`} component={Error}/>
                    <Route exact path={`${match.url}/sso/:userId`} component={Sso}/>
                    <Route exact path={`${match.url}/admin`} component={Login}/>
                    {general.disableLoginPage && <Route path={`${match.url}`} component={SsoLogin}/>}
                </Switch>
            </div>
        );
    }

    return (
        <div className={classes.root}>
            <TransitionRouter>
                {/* Routes for sso */}
                <Route exact path={`${match.url}/sso/aswatson-error`} component={AswatsonError}/>
                <Route exact path={`${match.url}/sso/new`} component={GateKeeper}/>
                <Route exact path={`${match.url}/sso/error`} component={Error}/>
                <Route exact path={`${match.url}/sso/:userId`} component={Sso}/>
                <Route exact path={`${match.url}/admin`} component={Login}/>

                { /* Temporary for CBD: */}
                {general.cbd && (<Route path={`${match.url}/registration/finished`} component={isIosApp ? Login : PaymentFinished}/>)}
                {general.cbd && (<Route path={`${match.url}/registration/:pay`} component={isIosApp ? Login : AppRegistrationCBD}/>)}
                {general.cbd && (<Route exact path={`${match.url}/registration`} component={isIosApp ? Login : AppRegistrationCBD}/>)}

                <Route path={`${match.url}/sso/:userId`} component={Sso}/>
                <Route path={`${match.url}/logout`} component={Logout}/>
                <Route path={`${match.url}/token/error`} component={ErrorToken}/>
                <Route path={`${match.url}/token`} component={LoginToken}/>
                <Route path={`${match.url}/login`} component={Login}/>
                <Route path={`${match.url}/pay`} component={isIosApp ? Login : PayScreen}/>
                <Route path={`${match.url}/webshop`} component={isIosApp ? Login : Webshop}/>
                <Route path={`${match.url}/payment-status/:id`} component={PaymentStatus}/>
                <Route path={`${match.url}/registration`} component={isIosApp ? Login : AppRegistrationView}/>
                <Route path={`${match.url}/2factor`} component={TwoFactor}/>
                <Route path={`${match.url}/accept-invitation/:token`} component={AppInvitation}/>
                <Route path={`${match.url}/reset-2factor/:token`} component={Confirm2FactorReset}/>
                <Route path={`${match.url}/reset-2factor`} component={Reset2Factor}/>
                <Route path={`${match.url}/invitation-succes/:token`} component={InvitationSucces}/>
                <Route path={`${match.url}/registration-success`} component={AppRegistrationSuccess}/>
                <Route path={`${match.url}/confirm-registration/:confirm`} component={AppConfirmRegistrationView}/>
                <Route path={`${match.url}/confirm-invitation/:token`} component={AppConfirmInvitation}/>
                <Route path={`${match.url}/reset`} component={ResetPassword}/>
                <Route path={`${match.url}/confirm-reset/:token`} component={ConfirmReset}/>
            </TransitionRouter>

            <TransitionRouter>
                <Route path={`${match.url}/login`} component={Homepage}/>
            </TransitionRouter>
        </div>
    );
};

export default AppAuthenticator;
